export const columns = [
  {
    name: 'uid',
    field: (row) => row.uid,
    label: 'Uid',
  },
  {
    name: 'agent',
    field: (row) => row.agent.username,
    label: 'Agent',
  },
  {
    name: 'inputCurrency',
    field: (row) => row?.inputCurrency?.name,
    label: 'Pre-payment currency',
  },
  {
    name: 'agentCurrency',
    field: (row) => row?.agentCurrency?.name,
    label: 'Agent currency',
  },
  {
    name: 'inputAmount',
    field: 'inputAmount',
    label: 'Input Amount',
  },
  {
    name: 'agentFee',
    field: (row) => row.agentFee,
    label: 'Agent Fee',
  },
  {
    name: 'agentAmount',
    field: (row) => row.agentAmount,
    label: 'Agent Amount',
  },
  {
    name: 'status',
    field: (row) => row.status,
    label: 'Status',
  },
  {
    name: 'type',
    field: (row) => row.type,
    label: 'Type',
  },
  {
    name: 'author',
    field: (row) => row.author.username,
    label: 'Author',
  },
  {
    name: 'comment',
    field: 'comment',
    label: 'Comment',
  },
  {
    name: 'createdAt',
    field: (row) => row.createdAt,
    label: 'createdAt',
  },
  {
    name: 'updatedAt',
    field: (row) => row.updatedAt,
    label: 'updatedAt',
  },
];
