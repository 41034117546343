<template>
  <q-table
    :grid="grid"
    :data="items"
    :columns="columns"
    :rows-per-page-options="[25, 50, 100]"
    with-pagination
    @on-pagination="$emit('on-pagination', $event)"
  >
    <template v-slot:top>
      <q-space />
      <q-btn
        v-if="isAdminOrSuper"
        unelevated
        icon="mdi-wallet-plus"
        color="primary"
        label="Create Pre-payment"
        :to="{ name: 'CreatePrePayment' }"
      />
    </template>
  </q-table>
</template>

<script>
import { columns } from './config';
import { ROLES } from '@/shared/constants';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import { authUtils } from '@/shared/utils';

export default {
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [withActionTableClasses],
  data: () => ({
    columns,
  }),
  computed: {
    isAdminOrSuper() {
      const role = authUtils.getRoleByHierarchy();
      return ROLES.ROLE_SUPERVISOR === role || ROLES.ROLE_ADMIN === role;
    },
  },
};
</script>

<style scoped lang="scss">
.virtual-scroll-table {
  height: 52em;
}
</style>
