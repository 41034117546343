var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-view-card',{ref:"view",attrs:{"fetch-function":_vm.prepaymentController.getPrepayments,"pagination":_vm.pagination,"filters":_vm.filters,"filter-transformer":_vm.clearFilters,"readonly-data":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var find = ref.find;
var data = ref.data;
var filtersEntity = ref.filtersEntity;
return [_c('div',{staticClass:"col-shrink row q-px-md q-pt-md"},[_c('q-card',{staticClass:"full-width"},[_c('q-card-section',{staticClass:"row col q-col-gutter-sm"},[(!_vm.isAgent)?_c('d-user-select',{attrs:{"label":"Agent","multiple":false,"use-input":"","emit-value":"","map-options":"","filled":"","dense":"","options":_vm.agents,"hide-bottom-space":"","hide-hint":""},model:{value:(filtersEntity.agentId),callback:function ($$v) {_vm.$set(filtersEntity, "agentId", $$v)},expression:"filtersEntity.agentId"}}):_vm._e(),_c('div',{style:({ minWidth: '160px' })},[_c('q-select',{attrs:{"options":_vm.statusOptions,"emit-value":"","map-options":"","dense":"","filled":"","clearable":"","label":"Status"},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('div',{style:({ minWidth: '160px' })},[_c('q-select',{attrs:{"options":_vm.typeOptions,"emit-value":"","map-options":"","dense":"","filled":"","clearable":"","label":"Type"},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),_c('d-date-range',{ref:"dateRange",attrs:{"value":[
              filtersEntity.createdAt.from,
              filtersEntity.createdAt.to ]},on:{"input":_vm.setDateRange}})],1),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{attrs:{"disable":loading,"outline":"","color":"primary","label":"Report"},on:{"click":_vm.onReportHandler}}),_c('q-btn',{staticClass:"q-px-sm",attrs:{"disable":loading,"unelevated":"","color":"primary","label":"Apply"},on:{"click":find}})],1)],1)],1),_c('div',{staticClass:"col-grow"},[_c('div',{staticClass:"q-pa-md"},[_c('d-payment-history',{attrs:{"grid":_vm.$q.screen.lt.md,"items":data}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }